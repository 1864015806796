
import { DefaultClientOrderLocationComponent } from 'app/helpers/controllers/default-client-order-location/default-client-order-location.component';
import {ProjectService} from '../../../../../../_services';
import {TranslateService} from '@ngx-translate/core';
declare const google: any;

export class DefaultOrderSearchLocationService {
    callbackResult: (result: any[], component: any) => void;
    ourDbResult: any[];
    googleResult: any[];

    constructor(
        private translate: TranslateService,
        private projectService: ProjectService,
        private defaultClientOrderLocationComponent: DefaultClientOrderLocationComponent,
        result: (result: any[],
                 component: any) => void
    ) {
        this.callbackResult = result;
    }

    private mergeResult() {
        if (this.ourDbResult !== null && this.googleResult !== null) {
            for (let x = 0; x < this.googleResult.length; x++) {
                const googleItem = this.googleResult[x];
                if (this.existOnOurDbResult(googleItem.place_id) === false) {
                    this.ourDbResult.push(
                        {
                          id: googleItem.place_id,
                          name: googleItem.description,
                          place_id: googleItem.place_id,
                          from_database: false
                        });
                }
            }
        }
        this.callbackResult(this.ourDbResult, this.defaultClientOrderLocationComponent);
   }

   existOnOurDbResult(id) {
       for (let b = 0; b < this.ourDbResult.length; b++) {
           const dbItem = this.ourDbResult[b];
           if (dbItem.place_id === id)
               return true;
       }
       return false;
   }

    search(text: string) {
        this.ourDbResult = null;
        this.googleResult = null;
        this.projectService.getLocationsDB(text).subscribe(response => {
            if (response.status === 'success') {
                this.ourDbResult = response.locations;
                this.mergeResult();
            } else {
                this.defaultClientOrderLocationComponent.translate.get('There was an error in the database! Please try again').subscribe(respons => {
                    this.defaultClientOrderLocationComponent.messageService.add({severity: 'error', summary: this.translate.instant('Error!'),
                        detail: respons});
                });

            }
        });

        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions(
            {
                input: text,
                componentRestrictions: { country: 'CH' }
            }, this.displaySuggestions(this.defaultClientOrderLocationComponent, this));
    }

    displaySuggestions(component: any, searchLocationService) {
        return function(predictions, status) {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
                return;
            }
            searchLocationService.googleResult = predictions;
            searchLocationService.mergeResult();
        };
    }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientService, LocationService, ProjectService } from 'app/_services';
import { DefaultOrderSearchLocationService } from 'app/cms/pages/default/project/basic-information/services/default-order-search-location.service';
import { ConfirmationService, MessageService } from 'primeng/api';


@Component({
  selector: 'app-default-client-order-location',
  templateUrl: './default-client-order-location.component.html',
  styleUrls: ['./default-client-order-location.component.scss'],
  providers: [
    MessageService,
    ConfirmationService,
  ]
})
export class DefaultClientOrderLocationComponent implements OnInit {

  @ViewChild('locationPopup') locationPopup;
  @ViewChild('defaultLocation') defaultLocation;
  @Input() clientId: any;
  public locations: Array<{ id: any, name: string }>;
  selectedLocation = null;
  selectedLocationName = '';
  ddText = {location: ''};
  isLoading = false;

  
  public DefaultOrderSearchLocationService: DefaultOrderSearchLocationService;

  constructor(
    public projectService: ProjectService,
    public translate: TranslateService,
    public messageService: MessageService,
    private confirmationService: ConfirmationService,
    private locationService: LocationService,
    private clientService: ClientService
  ) { 
    this.DefaultOrderSearchLocationService = new DefaultOrderSearchLocationService(translate, this.projectService, this, this.searchLocationResult);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.clientService.getClientOrderDefaultLocationId(this.clientId).subscribe(
      response => {
        console.log("the default location id is: ", response);
        this.selectedLocation = response.location;
        this.defaultLocation.searchTerm = response.location.name;
        this.isLoading = false;
        this.locationTextChange(this.defaultLocation.searchTerm); 
        this.changeDDText(this.defaultLocation.searchTerm, 'location');
      }
    );
  }

  addOwnLocation() {
    this.locationPopup.locationName = this.ddText.location;
    this.locationPopup.googleLocationName = '';
    this.locationPopup.locationId = '';
    this.locationPopup.place_id = '';
    this.locationPopup.showModal = true;
    this.locationPopup.isSubmitted = false;
  }

  locationSelected(selectedCity) {
    if (selectedCity != null) {
      if (selectedCity.Extra) {
        this.saveLocation(selectedCity);
      } else {
        this.locationPopup.locationName = selectedCity.name;
        this.locationPopup.googleLocationName = selectedCity.name;
        this.locationPopup.locationId = selectedCity.id;
        this.locationPopup.place_id = selectedCity.place_id;
        this.locationPopup.showModal = true;
      }
    }
  }

  searchFcn(term, item) {
    return item;
  }

  locationTextChange(text) {
    this.locations = [];
    if (text !== '') {
      this.DefaultOrderSearchLocationService.search(text);
    }
    return;
  }

  searchLocationResult(results: any[], component) {
    let locations = [];
    if (results === null)
      return;
    
    for (let i = 0; i < results.length; i++) {
      locations.push({
        id: results[i].id, place_id: results[i].place_id,
        name: results[i].name, Extra: results[i].from_database
      });
    }
    component.locations = [];
    component.locations = locations.slice();
  }

  changeDDText(text, type) {
    this.ddText[type] = text;
  }

  addLocationToDatabase(selectedCity) {
    if (typeof selectedCity.id === 'undefined') {
      selectedCity.id = null;
    }

    this.locationService.store({
      'name': selectedCity.name,
      'google_name': selectedCity.google_name,
      'place_id': selectedCity.place_id
    }).subscribe(response => {
      if (response.status === 'success') {
        this.saveLocation(response.loaction);
      } else {
        this.translate.get(response.message).subscribe(res => {
          this.messageService.add({
            severity: 'error', summary: this.translate.instant('Error!'),
            detail: res
          });
        });
      }
      this.locationPopup.showModal = false;
      this.locationPopup.isSubmitted = false;
    });
  }

  compareLocations(location1: any, location2: any): boolean {
    return location1 && location2 ? location1.id === location2.id : location1 === location2;
  }

  saveLocation(location) {
    this.selectedLocation = location;
    this.selectedLocationName = location.name;

    this.clientService.storeClientOrderDefaultLocation(this.clientId, {location: location}).subscribe( response => {
      this.messageService.add({
        severity: 'success', summary: this.translate.instant('Success!'),
        detail: this.translate.instant('default order location changed successfully!')
      });
    });
  }

  clearLocation() {
    this.selectedLocation = [];
    this.selectedLocationName = null;
    this.saveLocation([]);
  }
}
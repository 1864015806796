import {Component, Output, EventEmitter} from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-location-popup',
  templateUrl: '../views/location-popup.component.html',
  styleUrls: ['../styles/basic-info-style.scss']
})
export class LocationPopupComponent  {

    @Output() answerClick = new EventEmitter();

    public locationName: string;
    public googleLocationName: string;
    public locationId: any;
    public place_id: any;
    public showModal = false;
    public isSubmitted = false;

    constructor() { }


    response(answer: boolean) {
      if (answer)
        this.answerClick.emit(
          {
            id: this.locationId,
            name: this.locationName,
            google_name: this.googleLocationName,
            place_id: this.place_id
          });
        this.showModal = answer;
    }
    onSubmit(form) {
        if (form.valid === true) {
            this.isSubmitted = true;
            this.response(true);
        }
    }
}

import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginModel} from '../models/login.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AuthenticationService, PermissionService} from '../../_services';
import {TranslateService} from '@ngx-translate/core';
import {CodeInputComponent} from 'angular-code-input';
import {environment} from '../../../environments/environment';

@Component({
  moduleId: module.id,
  selector: 'app-login',
  templateUrl: `../views/login.component.html`,
  styleUrls: ['../styles/auth-style.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    ConfirmationService,
    MessageService
  ]
})
export class LoginComponent implements OnInit {
  public credentials: LoginModel;
  public displayTermsAndConditions = false;
  public passwordTypeField = true;
  returnUrl: string;
  resetEmail = '';
  resesetEmailObj = {
    email: '',
    password: '',
    code: ''
  };
  resetPassword = false;
  public isDropdownVisible = false;

  sendingEmail = false;

  verifyingCode = false;
  loadingCode = false;
  incorrectCode = false;
  code = '';
  resettingPassword = false;
  updatingPassword = false;
  @ViewChild('codeInput') codeInput !: CodeInputComponent;

  public showBrowserAlert = true;
  public isChrome = navigator.userAgent.indexOf("Chrome") != -1;
  public agreed = localStorage.getItem('chrome_suggestion_alert');

  public logoUrl = environment.logoUrl;
  public agencyName = environment.agencyName;

  public currentLanguage = localStorage.getItem('Language');

  constructor(
    private authService: AuthenticationService,
    private permissionService: PermissionService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService
  ) {
    this.credentials = new LoginModel();
    this.credentials.email = '';
    this.credentials.password = '';
  }

  ngOnInit() {
    if(this.route.snapshot.queryParams && this.route.snapshot.queryParams.type == "reset") {
      this.resetPassword = true;
      this.resetEmail = this.route.snapshot.queryParams.email;
      if (this.resetEmail.length) {
        this.resetEmail = this.resetEmail.toLowerCase();
      }
    }
    this.currentLanguage = localStorage.getItem('Language');
  }

  agreeOnChromeAlert() {
    localStorage.setItem('chrome_suggestion_alert', JSON.stringify(new Date()));
    this.showBrowserAlert = false
  }

  hideDropdown() {
    this.isDropdownVisible = false;
  }

  onSubmit(profileForm) {
    this.credentials.incorrect = false;
    if (!profileForm.valid) {
      return;
    }

    this.authService.login(this.credentials).subscribe(
      response => {
        if (response.status === 'success') {
          if(response.has_temp_password) {
            this.router.navigate(['/password-reset/' + response.token]);
            return;
          }
          localStorage.setItem('currentUser', JSON.stringify(response.user));
          if (response.user.language) this.changeLang(response.user.language);
          if (response.user.role === 'employee') {
            this.returnUrl = '/profile';
          } else if (response.permissions.projects) {
            this.returnUrl = '/project';
          } else if (response.permissions.employees) {
            this.returnUrl = '/employee';
          } else if (response.permissions.client_dashboard) {
            this.returnUrl = '/client-dashboard';
          } else if (response.permissions.agency_profile) {
            this.returnUrl = '/agency/profile/' + response.user.agency_id;
          } else if (response.permissions.agency_client_profile) {
            this.returnUrl = '/agency-client/profile/' + response.user.agency_id;
          } else if (response.permissions.client_work_validation) {
            this.returnUrl = '/client-work-validation/dashboard';
          }

          console.log(this.returnUrl);

          this.router.navigate([this.returnUrl]);
        } else if (response.status === 'not verified') {
            this.confirmationService.confirm({
                header: this.translate.instant("Confirmation"),
                message: `${this.translate.instant('Resend verification eMail?')}`,
                accept: () => {
                    // this.resendConfirmEmail();
                }
            });
        } else {
          this.credentials.incorrect = true;
          this.credentials.message = response.message;
        }
      },
      err => {
      });
    return;
  }

  submitReset(resetForm) {
    if (!resetForm.valid) {
      return;
    }
    this.sendingEmail = true;
    this.resesetEmailObj.email = this.resetEmail;
    this.authService.resetRequest(this.resesetEmailObj).subscribe(
      response => {
        this.sendingEmail = false;
        this.verifyingCode = true;
        this.translate.get('email to reset passwort was sent successfully.').subscribe( res => {
            this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: this.translate.instant(res)});
        })
      },
      error => {
        this.sendingEmail = false;
      },
    );
  }

  resendPasswordResetCode() {
    this.loadingCode = true;
    this.incorrectCode = false;
    this.authService.resendPasswordResetCode(this.resesetEmailObj).subscribe(
      response => {
        this.loadingCode = false;
        this.codeInput.reset();
        this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: this.translate.instant("code sent sucessfully")});
      },
      error => {
        this.loadingCode = false;
        this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: this.translate.instant("failed to send code")});
      }
    );
  }

  changeLang(lang) {
      this.translate.setDefaultLang(lang);
      localStorage.setItem('Language', lang);
      const url = window.location.href;
      const arr = url.split('/');
      window.location.href = arr[0] + '//' + arr[2] + this.router.url;
  }

  onCodeChanged(code: string) {
    if(code.length < 4) {
      this.incorrectCode = false;
    }
    this.code = code;
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.loadingCode = true;
    this.resesetEmailObj.code = code;
    this.authService.isResetPasswordCodeValid(this.resesetEmailObj).subscribe(
      response => {
        this.loadingCode = false;
        console.log('its valid');
        // go to new password form
        this.verifyingCode = false;
        this.resettingPassword = true;
      },
      error => {
        this.incorrectCode = true;
        this.loadingCode = false;
      }
    )
  }

  verify(){
    this.loadingCode = true;
    if(this.code.length == 4) {
      this.resesetEmailObj.code = this.code;
      this.authService.isResetPasswordCodeValid(this.resesetEmailObj).subscribe(
        response => {
          this.loadingCode = false;
          console.log('its valid');
          // go to new password form
          this.verifyingCode = false;
          this.resettingPassword = true;
        },
        error => {
          this.incorrectCode = true;
          this.loadingCode = false;
        }
      )
    } else {
      this.loadingCode = true;
      this.translate.get('incorrect code').subscribe(
        res => {
          this.loadingCode = false;
          this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
          this.incorrectCode = true;
          this.loadingCode = false;
        },
      );
    }
  }

  updatePassword() { 
    const passwordPattern = this.resesetEmailObj.password;
    this.updatingPassword = true;

    if (!passwordPattern.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[.!-]).{8,}$/)) {
     this.translate.get('must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters').subscribe(
       res => {
          this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
        });
      this.updatingPassword = false;
      return;
    }

    this.authService.updatePassword(this.resesetEmailObj).subscribe(
      response => {
        this.updatingPassword = false;
        this.translate.get("password changed successfully").subscribe(res => {
          console.log(response)
            this.messageService.add({severity: 'success', summary: this.translate.instant('Success!'), detail: res});
        });
       window.location.reload();
      },
      error => {
        this.updatingPassword = false;
        this.translate.get('failed to change password').subscribe(res => {
          this.messageService.add({severity: 'error', summary: this.translate.instant('Error!'), detail: res});
        });
      }
    );
  }

  getTermsAndConditionsUrl(): string {
    switch (this.currentLanguage) {
      case 'de':
        return 'https://job.rocks/allgemeine-geschaftsbedingungen';
      case 'fr':
        return 'https://job.rocks/fr/conditions-generales';
      case 'it':
        return 'https://job.rocks/it/condizioni-generali';
      default:
        return 'https://job.rocks/en/terms-and-conditions/';
    }
  }
}


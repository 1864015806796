export class UserIdentity {

  static isAuthenticated(): boolean {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return (currentUser !== null);
  }

  static getUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  static signOut() {
    const layout = localStorage.getItem('dashboardLayout');
    localStorage.clear();
    if (layout !== null) {
      localStorage.setItem('dashboardLayout', layout);
    }
  }
}

import {Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-load-template',
    templateUrl: './load-template-modal.component.html',
    styleUrls: ['./load-template-modal-style.scss']
})

export class LoadTemplateModalComponent implements OnInit {
    @Input() header: string;
    @Input() options: any;
    @Input() foundOptions: any;
    @Input() isLoading: any;

    @Input() label: any;
    @Input() optionsLabel: any;
    @Input() templateModalVisible: boolean;
    
    @Input() filterClients: boolean;
    @Input() filterClientsChecked: boolean;
    @Output() onToggleTemplateFilterClient = new EventEmitter<any>();

    @Output() onListClick = new EventEmitter<any>();
    @Output() onListClickByIndex = new EventEmitter<any>();
    @Output() onCloseModal = new EventEmitter<any>();


    constructor(private router: Router, private translate: TranslateService) { }
    
    ngOnInit() {}

    listClicked(option) {
       this.onListClick.emit(option);
    }

    listClickedByIndex(index) {
       this.onListClickByIndex.emit({ index: index, foundOptions: this.foundOptions });
    }

    search(value) {
        this.foundOptions = this.options.filter(function(element) {
            return element.name.toLowerCase().includes(value.toLowerCase());
        });
    }

    close() {
        this.onCloseModal.emit();
    }

    toggleTemplateFilterClient() {
        this.filterClientsChecked = !this.filterClientsChecked;
        this.onToggleTemplateFilterClient.emit(this.filterClientsChecked);
    }
}

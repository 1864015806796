import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class ClientService {
  constructor(private http: HttpClient) {}

  show(id) {
    return this.http.get<any>(`${environment.apiUrl}/client/show/${id}`);
  }

  getClients() {
    return this.http.get<any>(`${environment.apiUrl}/client`);
  }

  store(client) {
    return this.http.post<any>(`${environment.apiUrl}/client`, client);
  }

  getContacts(clientId) {
    return this.http.get<any>(`${environment.apiUrl}/project/contacts/${clientId}`);
  }

  getClientBuyers(clientId) {
    return this.http.get<any>(`${environment.apiUrl}/project/client-buyers/${clientId}`);
  }

  storeContact(contact) {
    return this.http.post<any>(`${environment.apiUrl}/client/contact`, contact);
  }

  getContact(contactId) {
    return this.http.get<any>(`${environment.apiUrl}/project/contact/${contactId}`);
  }

  updateContact(param, contactId) {
    return this.http.put<any>(`${environment.apiUrl}/project/contact/${contactId}`, param);
  }

  update(client) {
    return this.http.put<any>(`${environment.apiUrl}/client/${client.id}`, client);
  }

  delete(id) {
    return this.http.delete<any>(`${environment.apiUrl}/client/${id}`);
  }

  deleteContactPerson(id) {
    return this.http.delete<any>(`${environment.apiUrl}/client/contact/${id}`);
  }

  deleteClientLocation(id) {
    return this.http.delete<any>(`${environment.apiUrl}/client/location/${id}`);
  }
  
  deleteClientDepartment(id) {
    return this.http.delete<any>(`${environment.apiUrl}/client/department/${id}`);
  }

  deleteClientLogo(id) {
    return this.http.delete<any>(`${environment.apiUrl}/client/logo/${id}`);
  }

  getClientStaff(){
    return this.http.get<any>(`${environment.apiUrl}/client/staff`);
  }

  addClientRole(model){
    return this.http.post<any>(`${environment.apiUrl}/client/roles`, model)
  }
  editClientRole(id:any,model){
    return this.http.put<any>(`${environment.apiUrl}/client/roles/${id}`, model)
  }

  getClientRolesForAgency(clientId) {
    return this.http.get<any>(`${environment.apiUrl}/client/roles/${clientId}`)
  }

  getClientRoles(){
    return this.http.get<any>(`${environment.apiUrl}/client/roles`)
  }

  getClientRole(name){
    return this.http.get<any>(`${environment.apiUrl}/client/role/${name}`);
  }

  deleteClientRole(roleId){
    return this.http.delete<any>(`${environment.apiUrl}/client/roles/${roleId}`)
  }

  roleTemplates(){
    return this.http.get<any>(`${environment.apiUrl}/agency/template?type=role`)
  }

  inviteClientBuyer(params){
    return this.http.post<any>(`${environment.apiUrl}/client/invite-client-buyer`, params)
  }

  inviteTimestampSupervisor(params){
    return this.http.post<any>(`${environment.apiUrl}/client/invite-timestamp-supervisor`, params)
  }

  getClientOrderDefaultLocationId(id){
    return this.http.get<any>(`${environment.apiUrl}/client/order-default-location/${id}`)
  }

  storeClientOrderDefaultLocation(id, params){
    return this.http.post<any>(`${environment.apiUrl}/client/order-default-location/${id}`, params)
  }

  getTariffGroups(id) {
    return this.http.get<any>(`${environment.apiUrl}/client/get-tariff-groups/${id}`);
  }

  addTariffGroup(model) {
    return this.http.post<any>(`${environment.apiUrl}/client/add-tariff-group`, model)
  }

  editTariffGroup(model) {
    return this.http.post<any>(`${environment.apiUrl}/client/edit-tariff-group`, model)
  }

  deleteTariffGroup(id) {
    return this.http.delete<any>(`${environment.apiUrl}/client/delete-tariff-group/${id}`)
  }

  getDepartmentsByProjectId(id) {
    return this.http.get<any>(`${environment.apiUrl}/client/departments-by-project/${id}`)
  }

  getDepartmentReports(params) {
    return this.http.post<any>(`${environment.apiUrl}/client/department-reports`, params)
  }

  getMaleAndFemaleReports(params) {
    return this.http.post<any>(`${environment.apiUrl}/client/male-and-female-reports`, params)
  }

  getAgeSplitReports(params) {
    return this.http.post<any>(`${environment.apiUrl}/client/age-split-reports`, params)
  }

  getWorkTimeReports(params) {
    return this.http.post<any>(`${environment.apiUrl}/client/work-time-reports`, params)
  }

  getMissionsDataReports(params) {
    return this.http.post<any>(`${environment.apiUrl}/client/mission-data-reports`, params)
  }
}
